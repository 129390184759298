import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router';
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faCoffee as fasCoffee,
	faKey,
	faTachometerAlt,
	faAngleRight,
	faCog,
	faTable,
	faDoorClosed,
	faPhoneVolume,
	faMailBulk,
	faCashRegister,
	faAngleDown,
	faAngleUp,
	faStream,
	faPlusCircle,
	faColumns,
	faUndo,
	faFemale,
	faListUl,
	faExclamationTriangle,
	faChevronLeft,
	faMapMarkedAlt,
	faTimesCircle,
	faAlignLeft,
	faFilter,
	faBirthdayCake,
	faBars,
	faLevelDownAlt,
	faSpinner,
	faInfoCircle,
	faPhoneAlt,
	faSignature,
	faPen,
	faAngleDoubleDown,
	faAngleDoubleUp,
    faBan,
    faFile,
    faFileExcel,
    faFileCsv,
    faFilePdf,
    faFileVideo
} from '@fortawesome/free-solid-svg-icons'
import {
	faUser as farCoffee,
	faEye as farEye,
	faEyeSlash as farEyeSlash,
	faCopyright,
	faLifeRing,
	faComments,
	faUserCircle,
	faAddressCard,
	faSave,
	faIdBadge,
	faClipboard,
	faEdit,
	faTrashAlt,
	faHandshake,
	faAddressBook,
	faCalendarAlt,
	faEnvelope,
	faEnvelopeOpen,
	faWindowRestore,
	faCheckCircle,
	faThumbsDown,
	faThumbsUp
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFile, faFileCsv, faFileExcel, faFilePdf, faFileVideo, fasCoffee, farCoffee, farEye, farEyeSlash, faKey, faTachometerAlt, faAngleRight, faCog, faTable, faDoorClosed,
	faCopyright, faLifeRing, faComments, faPhoneVolume, faMailBulk, faUserCircle, faCashRegister, faAngleDown, faAngleUp,
	faStream, faPlusCircle, faColumns, faAddressCard, faSave, faUndo, faIdBadge, faFemale, faClipboard, faListUl, faExclamationTriangle,
	faEdit, faTrashAlt, faChevronLeft, faHandshake, faAddressBook, faMapMarkedAlt, faTimesCircle, faAlignLeft, faFilter,
	faBirthdayCake, faCalendarAlt, faBars, faLevelDownAlt, faSpinner, faInfoCircle, faPhoneAlt, faEnvelope, faEnvelopeOpen,
	faWindowRestore, faSignature, faPen, faAngleDoubleDown, faAngleDoubleUp, faBan, faCheckCircle, faThumbsDown, faThumbsUp)

const sweetalert_options = {
	confirmButtonColor: '#41b882',
	cancelButtonColor: '#ff7674',
};

var jQuery = require('jquery');
Object.defineProperty(window, "$", { configurable: false, enumerable: true, writable: false, value: jQuery });
Object.defineProperty(window, "jQuery", { configurable: false, enumerable: true, writable: false, value: jQuery });
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter);
Vue.use(VueSweetalert2, sweetalert_options);

Vue.component('fa', FontAwesomeIcon)

Vue.prototype.router = router;

Object.defineProperty(window, "formDataFromObj", {
  configurable: false,
  enumerable: true,
  writable: false,
  value: (obj, ignoreNullOrUndefined = true, formData = null, path = "") => {
      formData = !formData ? new FormData() : formData;
      if (obj) {
          for (var prop in obj) {
              if (ignoreNullOrUndefined) {
                  if (obj[prop] === null || obj[prop] === undefined) {
                      continue;
                  }
              }

              if (obj[prop] && !(obj[prop] instanceof File)  && (Array.isArray(obj[prop]) || typeof obj[prop] == 'object')) {
                  window.formDataFromObj(obj[prop], ignoreNullOrUndefined, formData, path ? `${path}[${prop}]` : prop);
              }
              else {
                  var key = path ? `${path}[${prop}]` : prop;
                  var value = obj[prop] === undefined || obj[prop] === null ? "" : obj[prop];
                  value = typeof value == "boolean" ? (value ? 1 : 0) : value;
                  formData.append(key, value);
              }
          }
      }

      return formData;
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
