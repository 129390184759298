import Vue from 'vue' 
import Http from '@/api/http'

const state = {
    user: null,
};

const getters = {
    user: state => {
        return state.user
    },
    has_permission: state => permission => state.user.employee.permissions.includes(permission)
};

const actions = {
    login({ commit }, params) {
        Http.post('auth/login', params).then(response => {
            let user = response.data.user
            user.token = response.data.token
            commit('SET_USER', response.data.user)
            //Vue.prototype.router.push('/');
        })
    },
    logout({ commit }) {
        //Vue.prototype.router.push('/');
        commit('LOGOUT')
    },
    data({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get(`auth/data`).then(response => {
                let user = response.data;
                commit('SET_USER', user)
                resolve(user);
            }).catch(error => {
                reject(error)
            })
        });
    }
};

const mutations = {
    SET_USER (state, value) {
        value = value && value.id_profile !== null && value.id_profile !== undefined ? value : null;
        Vue.set(state, "user", value);
    },
    UPDATE_TOKEN(state, token) {
        state.user.token = token
    },
    LOGOUT(state) {
        state.user = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};