import Vue from 'vue' 
import Http from '@/api/http';
import TrainingDomain from '@/domain/training'

const state = {
    trainings: [],
    training: new TrainingDomain(),
    // permissions: null,
    isUpdate: false,
    is_update: false,
    errors: {}
};

const getters = {
    // getPermissionModule: (state) => (module) => {
    //     return state.employee[`module_${module}`];
    // },
    IsUpdate: (state) => {
        return state.is_update
    },
};

const actions = {

    loadInsertForm({ commit, dispatch }) {
        commit('SET_TRAINING_ERROR', {});
        dispatch("IsUpdate", false);
        commit('SET_TRAINING', new TrainingDomain);
        // Http.get('employee/permissions').then(response => {
        //     commit('SET_PERMISSIONS', response.data);
        // })
    },

    loadEditForm({ commit, dispatch }, id) {
        dispatch("IsUpdate", true);
        commit('SET_TRAINING_ERROR', {});
        Http.get('training/' + id).then(response => {
            commit('SET_TRAINING', response.data.training)
            // commit('SET_PERMISSIONS', response.data.permissions)
        })
    },

    IsUpdate({ commit }, is_update) {
        commit("TRAINING_IS_UPDATE", is_update)
    },

    // updatePermission({ commit }, {module, new_value} ) {
    //     commit('UPDATE_PERMISSION', {module, new_value})
    // },

    getTrainings({ commit }, params) {
        return new Promise((resolve, reject) => {
            Http.get('training', {params: params}).then(response => {
                commit('SET_TRAININGS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getAllTrainings({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get('training/all').then(response => {
                commit('SET_TRAININGS', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getTrainingById({ commit }, id) {
        return new Promise((resolve, reject) => {
            Http.get('training/' + id).then(response => {
                commit('SET_TRAINING', response.data.training)
                resolve(response.data.training)
            }).catch(error => {
                reject(error)
            })
        })
    },

    createTraining({ state }) {
        return Http.post('training', state.training);
    },
    
    deleteTraining({commit}, training) {
        return new Promise((resolve, reject) => {
            Http.delete('training/' + training.id).then(response => {
                commit('DELETE_TRAINING', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateTraining({commit, state}) {
        return new Promise((resolve, reject) => {
            Http.put('training/' + state.training.id, state.training).then(response => {
                commit('UPDATE_TRAINING', response.data)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // * PHONE =============================
    createPhone({ commit, state }, index) {
        let phone = state.employee.person.phones[index]
        phone['person_id'] = state.employee.person.id

        return new Promise((resolve, reject) => {
            Http.post('phone', phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },

    updatePhone({ commit, state },index) {
        let phone = state.employee.person.phones[index]
        return new Promise((resolve, reject) => {
            Http.put(`phone/${phone.id}`, phone).then(response => {
                var phone_update = { index: index, data: response.data.phone }
                commit('SET_UPDATE_PHONE', phone_update)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },

    deletePhone({ commit, state },index) {
        return new Promise((resolve, reject) => {
            Http.delete(`phone/${state.employee.person.phones[index].id}`).then(response => {
                commit('SET_PHONE_DELETE', index)
                resolve(response.data)
            }).catch(error => {
                let err = { index: index, error: error.response.data }
                commit('SET_ADD_ERROR_IN_ARRAY', err)
                reject(error)
            })
        })
    },
    // * ===========================================

};

const mutations = {

    TRAINING_IS_UPDATE(state, is_update) {
        state.is_update = is_update
    },

    SET_TRAININGS(state, trainings) {
        state.trainings = trainings;
    },

    SET_TRAINING(state, training) {
        state.training = training;
    },
    
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },

    UPDATE_PERMISSION(state, {module, new_value}) {
        state.training[`module_${module}`] = new_value;
    },

    CREATE_TRAINING(state, training) {
        state.trainings.push({training});
    },

    UPDATE_TRAINING(state, training) {
        Vue.set(state.training, training);
    },

    DELETE_TRAINING(state, training) {
        state.training = training
    },

    // * ERRORS
    SET_CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_TRAINING_ERROR(state, value){
        state.errors = value
    },

    SET_ADD_ERROR_IN_ARRAY(state, err){
        var error_array = {}
        Object.keys(err.error).map((item) => {
            error_array[`${err.index}.${item}`] = err.error[item]
        });
        state.errors = error_array
    },
    
    //TRAINING
    SET_TITLE(state, value){
        state.training.title = value
    },

    SET_DESCRIPTION(state, value){
        state.training.description = value
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};