<template>
	<div v-if="LoggedUser" id="mary-help-treinamentos" :class="{ invertColors: $route.meta.InvertColors }">
		<template v-if="!$route.meta.plainLayout">
			<app-layout>
				<router-view v-if="!$route.meta.adminArea || IsProfileAdministrador()" :key="$route.path"></router-view>
				<div v-else style="margin-top: 15px" class="alert alert-danger text-center">
					Não autorizado 401
				</div>
			</app-layout>
		</template>
		<template v-else>
			<router-view v-if="!$route.meta.adminArea || IsProfileAdministrador()" :key="$route.path"></router-view>
			<div v-else style="margin-top: 15px" class="alert alert-danger text-center">
				Não autorizado 401
			</div>
		</template>
	</div>
	<div v-else>
		<div style="margin-top: 15px" class="alert alert-danger text-center">
			Necessário a autenticação no Sistema Mary Help
		</div>
	</div>
</template>

<script>
	import PermissionManager from '@/mixins/PermissionManager'
	import AppLayout from '@/components/AppLayout'
	import 'bootstrap/dist/css/bootstrap.css'
	import 'bootstrap-vue/dist/bootstrap-vue.css'
	import '@/assets/scss/main.scss'

	export default {
		name: 'App',
		mixins: [ PermissionManager ],
		components: { AppLayout },
		created() {
			this.security_copyright();
			this.$store.dispatch("auth/data");
		},
		methods: {
			security_copyright() {
				var copyright = "Este conteúdo não pode ser copiado e colado.";
				var preventClipboard = () => {
                    var deny = document.createElement("input");
                    deny.type = "text";
                    deny.value = copyright;
                    document.body.append(deny);
                    deny.select();
                    document.execCommand('copy');
                    deny.remove();
                }
                                
                document.body.oncontextmenu = function(e) {
                    e.preventDefault();
                    alert(copyright);
                };
                
                document.body.onselectstart = function(e) {
                    e.preventDefault();
                    return false;
                }

                document.body.onkeyup = function(e) {
                    if (e.keyCode == 44) {
                        e.preventDefault();
                        preventClipboard();
                        alert(copyright);
                        return false;
                    }
                }
                
                document.body.onkeydown = function(e) {
                    if (e.ctrlKey && e.keyCode == 67) {
                        e.preventDefault();
                        preventClipboard();
                        alert(copyright);
                    }
                    else if (e.keyCode == 123 || (e.ctrlKey && e.shiftKey && e.keyCode == 73) || (e.ctrlKey && e.keyCode == 80)) {
                        e.preventDefault();
                        alert(copyright);
                        return false;
                    }
                }
			}
		}
	}
</script>

<style lang="scss">
#mary-help-treinamentos {
	background-color: #F3F6F9;
	min-height: 100vh;
}

#mary-help-treinamentos.invertColors {
	background-color: #F6D2D6;
	
	#footer {
		background-color: #fff;
	}
}
</style>