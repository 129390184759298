<template>
<div class="richtable">
    <b-row>
        <b-col md="12">
            <b-row class="form-group">
                <b-col md="1">
                    <label for="pagination_show" class="control-label float-md-right">Mostrar: </label>
                </b-col>
                <b-col md="1">
                    <select name="pagination_show" v-model="model.perPage" @change="ChangeEntriesPerPage" id="pagination_show" class="form-control pr-0 pl-0">
                        <option v-for="o in perPageOptions" :key="o" :value="o">{{o}}</option>
                    </select>
                </b-col>
                <b-col md="2" offset="5">
                    <label for="search" class="control-label float-md-right">Pesquisar: </label>
                </b-col>
                <b-col md="3">
                    <input type="text" class="form-control search" v-model="model.search" @input="ChangeSearch" placeholder="Pesquisar">
                </b-col>
            </b-row>
        </b-col>
    </b-row>
    <b-row>
        <b-col md="12">
            <!-- <div v-if="loading" class="alert loading">
                Carregando...
            </div> -->
            <div :style="bodyHeight && bodyHeight != 'auto' && bodyHeight != '100%' ? { height: bodyHeight } : null" :class="bodyHeight && bodyHeight != 'auto' && bodyHeight != '100%' ? 'table-content-scroll' : null">
                <b-overlay :show="loading" rounded="sm">
                    <template #overlay>
                        <div class="text-center">
                            <h6 class="blink-2"><small>Carregando</small></h6>
                            <!-- <b-icon icon="stopwatch" animation="cylon" font-scale="2"></b-icon> -->
                        </div>
                    </template>

                    <b-table-simple responsive striped small hover class="p-0">
                        <colgroup>
                            <col :class="col.class" v-for="col in cols" :key="col.id">
                        </colgroup>
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for="col in cols" :key="col.id" @click="ChangeOrder(col)" :class="{'th-order': col.id, 'no-print': col.no_print}">
                                    <span v-if="col.input"><input :type="col.input" :class="col.input_class" v-model="col.input_value" /></span>
                                    <span>{{col.text}}</span>
                                    <span v-if="col.id" style="float: right;">
                                        <template v-if="col.id == model.sort_column">
                                            <i v-if="model.sort_order == 'asc'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                            <i v-else class="fa fa-sort-desc" aria-hidden="true"></i>
                                        </template>
                                        <template v-else>
                                            <i class="fa fa-sort" aria-hidden="true"></i>
                                        </template>
                                    </span>
                                </b-th>
                            </b-tr>
                        </b-thead>
                        
                        <b-tbody :class="{'loading-table': loading}">                            
                            <slot></slot>
                        </b-tbody>
                        <b-tfoot>
                            <slot name="footer"></slot>
                        </b-tfoot>
                    </b-table-simple>
                </b-overlay>
            </div>
        </b-col>
        <b-col md="6" class="no-print" v-if="numbers">
            <div v-if="bodyHeight && bodyHeight != 'auto' && bodyHeight != '100%'" class="form-group"></div>
            <p class="results_numbers">Mostrando de {{showing.from}} até {{(showing.to)}} de {{numbers.found}} registros <template v-if="numbers.total > numbers.found">(filtrado de {{numbers.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}} registros no total)</template></p>
        </b-col>
        <b-col md="6" class="no-print">

            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" id="datatable_previous">
                        <a @click="FirstPage()" aria-label="Anterior" v-show="model.page != 1" class="page-link">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="paginate_button page-item previous" id="datatable_previous">
                        <a @click="PreviousPage()" aria-label="Anterior" class="page-link" v-show="model.page != 1">
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li v-for="p in pages" :key="p" class="paginate_button page-item" :class="{'active': (model.page == p)}">
                        <a aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link" @click="ChangePage(p)">{{p}}</a>
                    </li>
                    <li class="paginate_button page-item next" id="datatable_next">
                        <a href="#" @click="NextPage()" aria-label="Próximo" v-show="model.page != lastPage" aria-controls="datatable" data-dt-idx="7" tabindex="0" class="page-link">&rsaquo;</a>
                    </li>
                    <li class="paginate_button page-item next" id="datatable_next">
                        <a href="#" @click="LastPage()" aria-label="Próximo" v-show="model.page != lastPage" aria-controls="datatable" data-dt-idx="7" tabindex="0" class="page-link">&raquo;</a>
                    </li>
                </ul>
            </div>

        </b-col>
    </b-row>
</div>
</template>

<script>
import TableConfig from '@/mixins/RichTableConfigStorage';

var timer = null;

export default {
    mixins: [TableConfig],
    data() {
        return {
            model: {
                perPage: 10,
                search: '',
                sort_column: '',
                sort_order: 'desc',
                page: 1
            },
            perPageOptions: [
                10, 25, 50, 100
            ],
            loaderImages: [
                'icon-nails', 
                'icon-hair-stylist', 
                'icon-massage', 
                'icon-lipstick'
            ]
        }
    },
    props: {
        cols: {
            type: Array
        },
        numbers: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        bodyHeight: {
            type: String,
            required: false
        },
        headerColor: {
            type: String,
            required: false
        },
        headerFixed: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        pages() {
            var items = [];

            var first = this.model.page - 2;

            var last = first + 4;
            if (last >= this.lastPage)
                last = this.lastPage

            if (this.model.page == this.lastPage)
                first = this.lastPage - 3

            if (first < 1)
                first = 1;

            for (var i = first; i <= last; i++)
                items.push(i);

            return items;
        },
        lastPage() {
            return Math.ceil(this.numbers.found / this.model.perPage);
        },
        showing() {
            var from = ((this.model.page - 1) * this.model.perPage) + 1;
            var to = from + this.numbers.size - 1;

            // Se não tiver registros, "Mostrando de 0 até 0"
            if (!this.numbers.found) {
                from = 0;
                to = 0;
            }
            return {
                from: from,
                to: to
            }
        }
    },
    created() {
        if (!this.table_config_loaded && this.cols)
            this.model.sort_column = this.cols[0].id

        this.EmitValues();
    },
    watch: {
        'numbers': {
            handler: function () {
                if (this.lastPage == 0) {
                    this.model.page = 1
                    this.EmitValues();
                } else if (this.model.page > this.lastPage) {
                    this.model.page = this.lastPage
                    this.EmitValues();
                }
            },
            deep: true
        }
    },
    methods: {
        ChangeSearch() {
            var vm = this
            clearTimeout(timer);
            timer = setTimeout(function () {
                vm.EmitValues()
            }, 500)
        },
        ChangeOrder(col) {
            if (!col.id)
                return
            if (this.model.sort_column == col.id)
                this.model.sort_order = this.model.sort_order == 'asc' ? 'desc' : 'asc';
            else
                this.model.sort_order = 'asc';

            this.model.sort_column = col.id
            this.EmitValues();
        },
        ChangeEntriesPerPage() {
            if (this.model.page > this.lastPage)
                this.model.page = this.lastPage
            this.EmitValues();
        },
        ChangePage(to) {
            this.model.page = to;
            this.EmitValues();
        },
        FirstPage() {
            this.ChangePage(1);
        },
        PreviousPage() {
            if (this.model.page > 1)
                this.ChangePage(this.model.page - 1);
        },
        NextPage() {
            if (this.model.page < this.lastPage)
                this.ChangePage(this.model.page + 1);
        },
        LastPage() {
            this.ChangePage(this.lastPage);
        },
        CleanFilters() {
            this.model = {
                perPage: 10,
                search: '',
                sort_column: this.cols[0].id,
                sort_order: 'asc',
                page: 1
            }
            this.EmitValues();
        },
        EmitValues() {
            this.SaveTableConfig(this.model)
            var values = {
                search: this.model.search,
                sort_column: this.model.sort_column,
                sort_order: this.model.sort_order,
                limit: this.model.perPage,
                offset: this.model.page > 0 ? (this.model.perPage * (this.model.page - 1)) : 0
            }
            this.$emit("input", values)
        },
    }
}
</script>

<style lang="scss" scoped>
.pagination {
    width: auto;
    right: 15px;
    position: absolute;
}

.pagination .active {
    background: #efecec;
}

.th-order,
.pagination li a {
    cursor: pointer
}

.loading {
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 999;
    background-color: #63636375;
    color: white;
    text-align: center;
    left: 0;
    right: 0;
}

.zero_records {
    text-align: center;
}

.loading-table {
    opacity: 0.3;
}

select.form-control:not([size]):not([multiple]) {
    height: auto;
}

.float-md-right {
    margin-top: 8px;
}

.table-content-scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

</style>
