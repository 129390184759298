export default {
    methods: {
        HasPermission(permission){
            var user = this.$store.state.auth.user;
            if(!user || !user.permissions || !user.permissions[permission])
                return false
            
            return user.permissions[permission] == true;
        },
        IsProfileAdministrador() {
            var user = this.$store.state.auth.user;
            return user && user.id_profile == 1;
        }
    },
    computed: {
        LoggedUser() {
            var user = this.$store.state.auth.user;
			return user;
        }
    }
}