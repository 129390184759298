import VueRouter from 'vue-router';

import Dashboard from '@/views/Dashboard/index'

import Training from '@/views/Admin/Training/index'
import TrainingNew from '@/views/Admin/Training/form'

import Module from '@/views/Admin/Module/index'
import ModuleNew from '@/views/Admin/Module/form'

import TrainingDetail from '@/views/Training/index'
import TrainingModule from '@/views/Training/module'
import ModuleBasic from "@/views/Training/module-basic"
import ModuleClean from "@/views/Training/module-clean"
import ModuleLaundry from "@/views/Training/module-laundry"
import ModulePdf from "@/views/Training/module-pdf"

const routes = [
    { path: '/', component: Dashboard, meta: { title: 'Dashboard' } },
    
    { path: '/admin/treinamentos', component: Training, meta: { title: 'Treinamentos', adminArea: true } },
    { path: '/admin/treinamentos/novo', component: TrainingNew, meta: { title: 'Novo Treinamento', adminArea: true } },
    { path: '/admin/treinamentos/:id', component: TrainingNew, meta: { title: 'Alterar Treinamento', adminArea: true} },
    
    { path: '/admin/modulos', component: Module, meta: { title: 'Módulos', adminArea: true } },
    { path: '/admin/modulos/novo', component: ModuleNew, meta: { title: 'Novo Módulo', adminArea: true } },
    { path: '/admin/modulos/:id', component: ModuleNew, meta: { title: 'Alterar Módulo', adminArea: true } },
    
    { path: '/treinamento/modulo-basico', component: ModuleBasic, meta: { title: 'Módulo Básico', plainLayout: true, hideBanner: true } },
    { path: '/treinamento/modulo-limpeza', component: ModuleClean, meta: { title: 'Módulo de Limpeza', plainLayout: true, hideBanner: true } },
    { path: '/treinamento/modulo-lavanderia', component: ModuleLaundry, meta: { title: 'Módulo de Lavanderia', plainLayout: true, hideBanner: true } },
    { path: '/treinamento/:id', component: TrainingDetail, meta: { title: 'Treinamento', hideBanner: true } },
    { path: '/treinamento/:id/modulo/:id_module', component: TrainingModule, meta: { title: 'Treinamento', hideBanner: true } },
    { path: '/treinamento/:id/modulo/:id_module/pdf/:id_file', component: ModulePdf, meta: { title: 'PDF', plainLayout: true, hideBanner: true } },
    // { path: '/login', component: Login, meta: { title: 'Login', plainLayout: true } },
    

    // { path: '/profissionais', component: Worker_General_Registrations, meta: { permission: 'worker-list', breadcrumb: [
    //     { name: 'Dashboard', link: '/' },
    //     { name: 'Profissionais' },
    //     { name: 'Cadastro Geral', active: true },  
    // ] } },
]

const router = new VueRouter({
	routes: routes,
	mode: 'history',
});

export default router