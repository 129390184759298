export default {
    created() {
        this.LoadStorageTableConfig();
    },
    data() {
        return {
            table_config_loaded: false
        }
    },
    computed: {
        storage_name() {
            return (this.$route.meta.shared_filter_storage ? this.$route.meta.shared_filter_storage : (this.$route.fullPath.endsWith("/") ? this.$route.fullPath.substring(0, this.$route.fullPath.length - 1) : this.$route.fullPath)) + "/_config"            
        }
    },
    methods: {
        LoadStorageTableConfig() {
            if(localStorage.getItem(this.storage_name)){
                this.table_config_loaded = true
                this.model = JSON.parse(localStorage.getItem(this.storage_name));
            }
        },
        SaveTableConfig(table_config) {
            table_config = JSON.stringify(table_config)
            localStorage.setItem(this.storage_name, table_config)
        }
    }
}
