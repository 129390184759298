<template>
    <div>
        <app-header></app-header>

        <div class="container-fluid">
            <div class="content">
                <slot></slot>
            </div>
        </div>

        <app-footer></app-footer>
    </div>
</template>

<script>
    import AppHeader from '@/components/AppHeader'
    import AppFooter from '@/components/AppFooter'
    
    export default {
        components: {
            AppHeader,
            AppFooter
        },
		created() {
			this.LoadMenu();
		},
		methods: {
			LoadMenu() {
				// this.$store.dispatch("system/load_menu")
			}
		}
    }
</script>

<style lang="scss">
.container-fluid {
    min-height: 100%;

    .content {
        padding-bottom: 80px;
    }
}
</style>