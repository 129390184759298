<template>
    <div class="reveal">
        <div class="slides">
            <section><img src="@/assets/img/module-laundry/Slide1.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide2.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide3.png" width="100%" height="auto"></section>
            <section>
                <div align="center">
                    <video data-autoplay src="https://s3.amazonaws.com/maryhelp-test/module_attachments/79978f269c9bf130c98550cd16b1d0ca.mp4"  controls preload="metadata" controlsList="nodownload"></video>
                </div>
            </section>
            <section><img src="@/assets/img/module-laundry/Slide4.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide5.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide6.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide7.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide8.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide9.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide10.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide11.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide12.png" width="100%" height="auto"></section>
            <section>
                <div align="center">
                    <video data-autoplay src="https://s3.amazonaws.com/maryhelp-test/module_attachments/163e16f77128e91f86fa371b2894c670.mp4"  controls preload="metadata" controlsList="nodownload"></video>
                </div>
            </section>
            <section><img src="@/assets/img/module-laundry/Slide13.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide14.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide15.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide16.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide17.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-laundry/Slide18.png" width="100%" height="auto"></section>
        </div>
    </div>
</template>

<script>
import Reveal from 'reveal.js/js/reveal';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';

export default {
    mounted() {
        var reveal = new Reveal({
            plugins: [ Markdown ]
        });
        reveal.initialize();
    },
};
</script>

<style>
    @import url('../../../node_modules/reveal.js/dist/reveal.css');
    @import url('../../../node_modules/reveal.js/dist/theme/black.css');
    
    .reveal-viewport #mary-help-treinamentos {
      width: 100vw;
      height: 100vh;
      min-height: initial !important;
      background-color: transparent;
    }

    .slides > section {
        top: 0% !important;
    }
</style>