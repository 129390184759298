// import Vue from 'vue';
import axios from 'axios';
import swal from "sweetalert2";
import store from '@/store';

const client = axios.create({
    baseURL: '/api/',
    timeout: 3000000
});

client.interceptors.request.use(config => {
    if (store.getters['auth/user'] && store.getters['auth/user'].token) {
        config.headers.common.Authorization = `bearer ${store.getters['auth/user'].token}`;
    }
  
    return config;
  }, error => {
    return Promise.reject(error);
});


client.interceptors.response.use((response) => {
    if (response.headers.token != undefined && response.headers.token != '') {
        let token = response.headers.token;
        store.commit("UPDATE_TOKEN", token);
    }   
    return response;
}, (error) => {
    if (error.response){
        var status = error.response.status;
        if(status == 401) {
            store.dispatch('auth/logout');
        } else if(status == 400 || status == 500) {
            if(typeof(error.response.data) === 'string'){
                swal.fire("", error.response.data, "error");
            }
            else {
                swal.fire("", "Ocorreu um erro!", "error")
            }
        } else if (status == 422) {
            if(typeof(error.response.data) === 'string')
                swal.fire("", error.response.data, "error");
            else if(typeof(error.response.data) === 'object' && error.response.data.errors) {
                let message = '';
                Object.entries(error.response.data.errors).forEach((value) => {
                    message += value[0] + '<br/>';
                })
                swal.fire("", message, "error");
            }
            
        }
    } else {
        swal.fire("", "Ocorreu um erro!", "error")
    }
    
    return Promise.reject(error);
});



export default client; 